<template>
  <div>
    <div v-if="customer" class="profile-informations">
      <b-row>
        <b-col class="settings-title" cols="10">
          {{ $t('views.client.details.body.left.subscription-affect.subscription-resume-label') }}
        </b-col>
      </b-row>
      <b-row class="mt-3 mb-3">
        <b-col cols="1">
          <img class="customer-img"
               v-if="src"
               :src="src"
               height="60"
               width="60"
          >
          <template v-else>
            <d-avatar
              :full-name="fullName"
              size="60"
              color="#DDDDDD"
              text-color="#4D4F5C"
              text-font-size="30"
            />
          </template>
        </b-col>
        <b-col>
          <b-row class="ml-2 mt-1">
            <b-col
              class="black-customer-fullname"
              cols="12"
              :class="$store.getters['layout/getInnerWidth'] > 900 ? 'ml-2' : 'ml-4'"
            >
              {{ fullName + (customer.phoneNumber ? ' (' + customer.phoneNumber + ')' : '') }}
            </b-col>
            <b-col class="gray-text-subtitle" cols="12">
              {{ email }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div v-if="selectedCreditsPack.selected" class="selected-subscription-informations">
        <b-row>
          <b-col class="selected-subscription">
            {{ selectedCreditsPack.values.name }}

            <b-row v-if="selectedCreditsPack.selected">
              <b-col class="mt-3 gray-text-description" cols="12">
                {{ selectedCreditsPack.values.description }}
              </b-col>
            </b-row>
          </b-col>
          <b-col class="selected-subscription" align="right">
            <div>
              {{ parseFloat('' + selectedCreditsPack.values.price).toFixed(2) + $currency }}
            </div>
            <div v-if="selectedCreditsPack.values.nextoreProductReference">

              <d-button
                text="views.client.details.body.left.subscription-affect.checkout-subscription"
                icon="fa fa-fax"
                class="d-btn-sm btn d-btn-primary font-text-title btn-full-width mb-2"
                :class="selectedCreditsPack.values.nextoreProductReference ? '' : 'disabled'"
                @on:button-click="onSubscriptionCheckout()"
              />

              <d-button
                text="views.client.details.body.left.subscription-affect.checkout-pack-credit"
                icon="fa fa-fax"
                class="d-btn-sm btn d-btn-primary font-text-title btn-full-width"
                :class="selectedCreditsPack.values.nextoreProductReference ? '' : 'disabled'"
                @on:button-click="creditPriceCheckout()"
              />
            </div>
          </b-col>
        </b-row>

      </div>
      <div v-if="selectedCreditsPack.selected">
        <b-row class="mt-3 mb-3">
          <b-col class="selected-subscription">
            {{ $t('views.client.details.body.left.credits-pack-affect.details-credits-pack') }}
          </b-col>
        </b-row>
        <template
          v-for="(item, i) of selectedCreditsPack.values.items"
        >
          <b-row
            :key="'credits-pack-col' + i"
            class="mt-3"
          >
            <b-col
              cols="12"
              class="black-pack-fullname"
            >
              {{ item.quantity }} X {{ item.paymentToken.name }}
            </b-col>
            <b-col v-if="item.paymentToken.validityDurationCount"
                   cols="12"
                   class="gray-text-description"
            >
              {{ $t('views.client.details.body.right.balance.expires-at') }}
              {{ getExpiresAtDate(item.paymentToken.validityDurationCount, item.paymentToken.validityPeriodicityType) }}
            </b-col>
            <b-col v-else
                   cols="12"
                   class="gray-text-description"
            >
              {{ $t('views.client.details.body.right.balance.expires-at') }}
              {{ $t('views.client.details.body.right.balance.permanent') }}
            </b-col>
          </b-row>
        </template>
      </div>
    </div>
  </div>
</template>
<script>

import Product from "@/classes/nextore/Product";

export default {
  props: {
    customer: {
      type: Object,
      default: null
    },
    selectedCreditsPack: {
      type: Object,
      default: () => ({
        values: null,
        selected: false,
      })
    },
  },
  computed: {
    src() {
      if (this.customer) {
        if (this.customer.identityPhoto) {
          return this.$filePath(this.customer.identityPhoto.contentUrl);
        } else if (this.customer.user) {
          if (this.customer.user.avatar) {
            return this.$filePath(this.customer.user.avatar.contentUrl);
          }
        }
        return null;
      } else {
        return null;
      }
    },
    fullName() {
      if (this.customer) {
        if (this.customer.lastName === null) {
          return this.customer.fullName;
        } else {
          try {
            const indexOfLastName = this.customer.fullName.indexOf(this.customer.lastName);
            const firstName = this.customer.fullName.substring(0, indexOfLastName - 1);
            const lastName = this.customer.fullName.substring(indexOfLastName);

            return firstName.length === 0 ? firstName : firstName + ' ' + lastName.toUpperCase();
          } catch (e) {
            return this.customer.fullName;
          }
        }
      }
    },
    email() {
      return this.customer ? this.customer.email : this.$t('general.actions.undefined');
    }
  },
  methods: {
    creditPriceCheckout() {
      const club = this.$store.getters['auth/currentClub'];

      if (club.nextoreAccount) {
        const product = new Product();
        product.id = this.selectedCreditsPack.values.nextoreProductReference;
        product.name = this.selectedCreditsPack.values.name;
        product.price = parseFloat('' + this.selectedCreditsPack.values.price);
        product.quantity = 1;

        this.$store.dispatch('nextore/setCustomer', this.customer);
        this.$store.dispatch('nextore/addProduct', product);
        this.display = true;
      }
    },
    getExpiresAtDate(validityDurationCount, validityPeriodicityType) {
      return this.$moment.utc().add(validityDurationCount, validityPeriodicityType).format('DD/MM/YYYY');
    },
    onSubscriptionCheckout() {
      const club = this.$store.getters['auth/currentClub'];
      if (club.nextoreAccount) {
        const product = new Product();
        product.id = club.nextoreAccount.feesProductReference;
        product.name = this.$t('views.client.details.body.left.subscription-affect.fees-rate');
        product.price = 1;
        product.quantity = 1;
        this.$store.dispatch('nextore/setCustomer', this.customer);
        this.$store.dispatch('nextore/addProduct', product);
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.customer-img {
  border-radius: 50%;
  border: 1px solid #f1f1f1;
  object-fit: cover;
}

.gray-text-subtitle {
  font: normal normal 15px Avenir;
  letter-spacing: 0px;
  color: #adadae;
  opacity: 1;
}

.gray-text-description {
  font: normal normal 15px Avenir;
  letter-spacing: 0px;
  color: #929294;
  opacity: 1;
}

.black-customer-fullname {
  text-align: left;
  font: normal normal normal 16px Avenir;
  letter-spacing: 0.43px;
  color: #333333;
}

.black-pack-fullname {
  text-align: left;
  font: normal normal normal 14px Avenir;
  letter-spacing: 0.2px;
  color: #333333;
}

.selected-subscription {
  font: normal normal bold 16px Avenir;
  letter-spacing: 0px;
  color: #403d3d;
  opacity: 1;
}

/deep/ .has-calendar .form-control-feedback {
  line-height: 2.675rem;
}
</style>
